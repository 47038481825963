import { createFrame } from './helpers';
export function handlePayload(dispatcher, event) {
    switch (event.data.type) {
        case 'popup': {
            var _a = event.data, flow = _a.flow, params = _a.params;
            var index = dispatcher.registerPayload({ flow: flow, params: params });
            var popupFrame = createFrame('/flow.html?' + encodeURIComponent(index), {
                width: '100%',
                height: '100%'
            }, {
                title: 'Widget Title',
                dataTest: 'infoWidget'
            });
            popupFrame.style.left = '0';
            popupFrame.style.top = '0';
            popupFrame.style.position = 'fixed';
            popupFrame.style.backgroundColor = 'transparent';
            popupFrame.style.zIndex = '999';
            dispatcher.registerIframe(popupFrame, index);
            return;
        }
        case 'close-popup': {
            return dispatcher.unregisterIframe(event.data.index);
        }
        case 'request-payload': {
            return dispatcher.sendPayload(event.data.index);
        }
        case 'get-shared-data': {
            return dispatcher.sendSharedData(event.source);
        }
        case 'set-shared-data': {
            return dispatcher.setSharedData(event.data.payload);
        }
        case 'set-height': {
            return dispatcher.setFrameHeight(event.data.index, event.data.height);
        }
        case 'interframe-event': {
            return dispatcher.propagateEvent(event.data.eventType, event.data.payload);
        }
        case 'get-host-url': {
            return dispatcher.sendHostUrl(event.source);
        }
        case 'redirect': {
            document.location = event.data.url;
            return;
        }
    }
}
